import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LogoContainer = styled(motion.div)`
  img {
    height: 100px;
    width: auto;
  }
`;

const LoadingScreen = () => {
  return (
    <LoadingContainer>
      <LogoContainer
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      >
        <img src="/logo.png" alt="Loading..." />
      </LogoContainer>
    </LoadingContainer>
  );
};

export default LoadingScreen; 