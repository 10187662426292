import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaAngleDown, FaPlay } from 'react-icons/fa';

const HeroSection = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url('https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?q=80&w=2070') center/cover no-repeat;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(18, 18, 18, 0.95) 0%,
      rgba(255, 77, 0, 0.85) 100%
    );
  }
`;

const HeroContent = styled(motion.div)`
  text-align: left;
  max-width: 1200px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContent = styled(motion.div)`
  flex: 1;
`;

const Title = styled(motion.h1)`
  font-size: 4.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.light};
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.2;
  
  span {
    display: block;
    background: ${props => props.theme.colors.gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 2.8rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.light};
  margin-bottom: 2.5rem;
  opacity: 0.9;
  max-width: 600px;
  line-height: 1.6;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 1.2rem;
    margin: 0 auto 2rem;
  }
`;

const ButtonContainer = styled(motion.div)`
  display: flex;
  gap: 20px;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: center;
  }
`;

const HeroButton = styled(motion.button)`
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  background: ${props => props.primary ? props.theme.colors.gradients.primary : 'transparent'};
  color: ${props => props.theme.colors.light};
  border-radius: 50px;
  border: ${props => props.primary ? 'none' : `2px solid ${props.theme.colors.primary}`};
  transition: all 0.3s ease;
  box-shadow: ${props => props.primary ? props.theme.shadows.medium : 'none'};
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: ${props => props.primary ? props.theme.colors.gradients.primary : props.theme.colors.primary};
    transform: translateY(-3px);
    box-shadow: ${props => props.theme.shadows.large};
  }
`;

const ImageContent = styled(motion.div)`
  flex: 1;
  position: relative;
  height: 500px;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const FloatingImage = styled(motion.img)`
  position: absolute;
  border-radius: 20px;
  box-shadow: ${props => props.theme.shadows.large};
`;

const Image1 = styled(FloatingImage)`
  width: 300px;
  height: 400px;
  object-fit: cover;
  top: 0;
  right: 0;
`;

const Image2 = styled(FloatingImage)`
  width: 250px;
  height: 300px;
  object-fit: cover;
  bottom: 0;
  left: 0;
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.colors.light};
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
`;

const Hero = () => {
  const scrollToServices = () => {
    document.getElementById('services').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HeroSection id="home">
      <HeroContent>
        <TextContent>
          <Title>
            Transform Your Events
            <span>With Premium Sound & Light</span>
          </Title>
          <Subtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Experience the perfect harmony of cutting-edge technology and creative excellence
            for your next event. We bring your vision to life with stunning audio-visual solutions.
          </Subtitle>
          <ButtonContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <HeroButton
              primary
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaPlay /> Watch Showreel
            </HeroButton>
            <HeroButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Our Services
            </HeroButton>
          </ButtonContainer>
        </TextContent>
        <ImageContent>
          <Image1
            src="https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?q=80&w=2070"
            alt="Concert Production"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          />
          <Image2
            src="https://images.unsplash.com/photo-1504680177321-2e6a879aac86?q=80&w=2070"
            alt="Lighting Setup"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          />
        </ImageContent>
      </HeroContent>
      <ScrollIndicator
        animate={{ y: [0, 10, 0] }}
        transition={{ duration: 1.5, repeat: Infinity }}
        onClick={scrollToServices}
      >
        <FaAngleDown />
      </ScrollIndicator>
    </HeroSection>
  );
};

export default Hero; 