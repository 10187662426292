import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaFacebook, FaInstagram, FaGlobe, FaPhone, FaEnvelope, FaArrowUp } from 'react-icons/fa';

const FooterSection = styled.footer`
  padding: 80px 0 30px;
  background: linear-gradient(180deg, ${props => props.theme.colors.dark} 0%, #000000 100%);
  border-top: 1px solid rgba(255, 77, 0, 0.1);
  position: relative;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 60px;
  margin-bottom: 50px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Logo = styled.div`
  margin-bottom: 20px;
  img {
    height: 70px;
    width: auto;
  }
`;

const Text = styled.p`
  color: ${props => props.theme.colors.light};
  opacity: 0.8;
  line-height: 1.8;
  font-size: 1.1rem;
  max-width: 400px;
`;

const Title = styled.h4`
  color: ${props => props.theme.colors.primary};
  font-size: 1.4rem;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: ${props => props.theme.colors.gradients.primary};
    border-radius: 2px;
  }
`;

const Link = styled.a`
  color: ${props => props.theme.colors.light};
  opacity: 0.8;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.1rem;

  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.primary};
    transform: translateX(5px);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;

const SocialIcon = styled.a`
  color: ${props => props.theme.colors.light};
  font-size: 1.8rem;
  transition: all 0.3s ease;
  background: rgba(255, 77, 0, 0.1);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 77, 0, 0.2);

  &:hover {
    color: ${props => props.theme.colors.primary};
    transform: translateY(-5px);
    background: rgba(255, 77, 0, 0.2);
  }
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 77, 0, 0.2),
    transparent
  );
  margin: 30px 0;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
`;

const Copyright = styled.div`
  color: ${props => props.theme.colors.light};
  opacity: 0.8;
`;

const DevelopedBy = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const BackToTop = styled(motion.button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => props.theme.colors.gradients.primary};
  color: ${props => props.theme.colors.light};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  box-shadow: ${props => props.theme.shadows.medium};
  z-index: 999;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
`;

const Footer = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <FooterSection>
      <Container>
        <TopSection>
          <Column>
            <Logo>
              <img src="/logo.png" alt="Phoenix Global Solutions" />
            </Logo>
            <Text>
              Leading provider of professional sound and lighting solutions across the United States. 
              Transforming events with cutting-edge technology and exceptional service.
            </Text>
            <SocialLinks>
              <SocialIcon href="https://facebook.com/phoenixgsolutions" target="_blank">
                <FaFacebook />
              </SocialIcon>
              <SocialIcon href="https://instagram.com/phoenixgsolutions" target="_blank">
                <FaInstagram />
              </SocialIcon>
              <SocialIcon href="https://phoenixssolutions.com" target="_blank">
                <FaGlobe />
              </SocialIcon>
            </SocialLinks>
          </Column>
          <Column>
            <Title>Quick Links</Title>
            <Link href="#home">Home</Link>
            <Link href="#services">Services</Link>
            <Link href="#about">About</Link>
            <Link href="#portfolio">Portfolio</Link>
            <Link href="#contact">Contact</Link>
          </Column>
          <Column>
            <Title>Contact Info</Title>
            <Link href="tel:818-984-7763">
              <FaPhone /> 818-984-7763
            </Link>
            <Link href="mailto:info@phoenixssolutions.com">
              <FaEnvelope /> info@phoenixssolutions.com
            </Link>
            <Link href="https://facebook.com/phoenixgsolutions" target="_blank">
              <FaFacebook /> /phoenixgsolutions
            </Link>
            <Link href="https://instagram.com/phoenixgsolutions" target="_blank">
              <FaInstagram /> /phoenixgsolutions
            </Link>
            <Link href="https://phoenixssolutions.com" target="_blank">
              <FaGlobe /> phoenixssolutions.com
            </Link>
          </Column>
        </TopSection>
        <Divider />
        <BottomSection>
          <Copyright>
            © {new Date().getFullYear()} Phoenix Global Solutions. All rights reserved.
          </Copyright>
          <DevelopedBy href="https://olexto.com" target="_blank">
            Developed by Olexto Digital Solutions
          </DevelopedBy>
        </BottomSection>
      </Container>

      <AnimatePresence>
        {showBackToTop && (
          <BackToTop
            onClick={scrollToTop}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaArrowUp />
          </BackToTop>
        )}
      </AnimatePresence>
    </FooterSection>
  );
};

export default Footer; 