import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaSearch, FaLink } from 'react-icons/fa';

const PortfolioSection = styled.section`
  padding: 100px 0;
  background: ${props => props.theme.colors.gradients.dark};
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, rgba(18, 18, 18, 1), transparent);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 20px;
  background: ${props => props.theme.colors.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SectionSubtitle = styled.p`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 50px;
  color: ${props => props.theme.colors.light};
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto 60px;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  position: relative;
  z-index: 1;
`;

const ProjectCard = styled(motion.div)`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 400px;
  box-shadow: ${props => props.theme.shadows.medium};
  cursor: pointer;

  &:hover .overlay {
    opacity: 1;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
`;

const ProjectOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colors.gradients.overlay};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 30px;
  class: overlay;
`;

const ProjectTitle = styled.h3`
  color: ${props => props.theme.colors.light};
  font-size: 1.8rem;
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const ProjectDescription = styled.p`
  color: ${props => props.theme.colors.light};
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 25px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const Icon = styled(motion.div)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => props.theme.colors.gradients.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.light};
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: ${props => props.theme.shadows.medium};
`;

const Portfolio = () => {
  const projects = [
    {
      title: "Music Festival Production",
      description: "Complete sound and lighting setup for a 3-day music festival. Featuring line array systems and intelligent lighting.",
      image: "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?q=80&w=2070",
      link: "#"
    },
    {
      title: "Corporate Event",
      description: "Audio-visual solutions for annual corporate conference with multi-room setup and live streaming capabilities.",
      image: "https://images.unsplash.com/photo-1511578314322-379afb476865?q=80&w=2069",
      link: "#"
    },
    {
      title: "Concert Production",
      description: "Full-scale production for live concert venue including sound, lighting, and special effects.",
      image: "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?q=80&w=2070",
      link: "#"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <PortfolioSection id="portfolio">
      <Container>
        <SectionTitle
          as={motion.h2}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Our Portfolio
        </SectionTitle>
        <SectionSubtitle
          as={motion.p}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Showcasing our finest work in event production and audio-visual excellence
        </SectionSubtitle>
        <ProjectGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              variants={itemVariants}
              whileHover={{ y: -10 }}
            >
              <ProjectImage src={project.image} alt={project.title} />
              <ProjectOverlay className="overlay">
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.description}</ProjectDescription>
                <IconContainer>
                  <Icon whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    <FaSearch />
                  </Icon>
                  <Icon whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    <FaLink />
                  </Icon>
                </IconContainer>
              </ProjectOverlay>
            </ProjectCard>
          ))}
        </ProjectGrid>
      </Container>
    </PortfolioSection>
  );
};

export default Portfolio; 