import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { validateEmail, sanitizeInput } from '../utils/validation';
import rateLimiter from '../utils/rateLimiter';

const ContactSection = styled.section`
  padding: 100px 0;
  background: ${props => props.theme.colors.gradients.dark};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(18, 18, 18, 0.8), transparent);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ContactInfo = styled.div`
  padding: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 20px;
  background: ${props => props.theme.colors.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const InfoCard = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(30, 30, 30, 0.6);
  border-radius: 10px;
  border: 1px solid rgba(255, 77, 0, 0.1);
  backdrop-filter: blur(10px);
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => props.theme.colors.gradients.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.light};
  font-size: 1.2rem;
`;

const InfoContent = styled.div`
  color: ${props => props.theme.colors.light};
  h3 {
    margin-bottom: 5px;
    font-size: 1.2rem;
  }
  p {
    opacity: 0.8;
  }
`;

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  background: rgba(30, 30, 30, 0.6);
  border-radius: 15px;
  border: 1px solid rgba(255, 77, 0, 0.1);
  backdrop-filter: blur(10px);
`;

const Input = styled(motion.input)`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 77, 0, 0.1);
  background: rgba(18, 18, 18, 0.8);
  color: ${props => props.theme.colors.light};
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: ${props => props.theme.shadows.small};
  }
`;

const TextArea = styled(motion.textarea)`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 77, 0, 0.1);
  background: rgba(18, 18, 18, 0.8);
  color: ${props => props.theme.colors.light};
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: ${props => props.theme.shadows.small};
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 15px 30px;
  background: ${props => props.theme.colors.gradients.primary};
  color: ${props => props.theme.colors.light};
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  box-shadow: ${props => props.theme.shadows.medium};

  &:hover {
    box-shadow: ${props => props.theme.shadows.large};
  }
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.error};
  font-size: 0.9rem;
  margin-top: 5px;
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    
    if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: sanitizeInput(value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!rateLimiter.checkLimit('user_ip')) {
      alert('Too many requests. Please try again later.');
      return;
    }

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Add your form submission logic here
      // Example with security headers:
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      // Clear form after successful submission
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContactSection id="contact">
      <Container>
        <ContactInfo>
          <SectionTitle
            as={motion.h2}
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Get In Touch
          </SectionTitle>
          <InfoCard
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            <IconWrapper>
              <FaPhone />
            </IconWrapper>
            <InfoContent>
              <h3>Phone</h3>
              <p>818-984-7763</p>
            </InfoContent>
          </InfoCard>
          <InfoCard
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
          >
            <IconWrapper>
              <FaEnvelope />
            </IconWrapper>
            <InfoContent>
              <h3>Email</h3>
              <p>info@phoenixssolutions.com</p>
            </InfoContent>
          </InfoCard>
          <InfoCard
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
          >
            <IconWrapper>
              <FaMapMarkerAlt />
            </IconWrapper>
            <InfoContent>
              <h3>Social Media</h3>
              <p>Facebook: /phoenixgsolutions</p>
              <p>Instagram: /phoenixgsolutions</p>
            </InfoContent>
          </InfoCard>
        </ContactInfo>
        <Form
          onSubmit={handleSubmit}
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
        >
          <Input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
            whileFocus={{ scale: 1.02 }}
            aria-invalid={errors.name ? 'true' : 'false'}
          />
          {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}

          <Input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
            whileFocus={{ scale: 1.02 }}
            aria-invalid={errors.email ? 'true' : 'false'}
          />
          {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}

          <Input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
            whileFocus={{ scale: 1.02 }}
          />

          <TextArea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
            whileFocus={{ scale: 1.02 }}
            aria-invalid={errors.message ? 'true' : 'false'}
          />
          {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}

          <SubmitButton
            type="submit"
            disabled={isSubmitting}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </SubmitButton>
        </Form>
      </Container>
    </ContactSection>
  );
};

export default Contact; 