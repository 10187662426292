import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaMusic, FaLightbulb, FaTheaterMasks, FaMicrophone, FaVideo, FaCog } from 'react-icons/fa';

const ServicesSection = styled.section`
  padding: 100px 0;
  background: ${props => props.theme.colors.gradients.dark};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(18, 18, 18, 0.8), transparent);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SectionSubtitle = styled.p`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 50px;
  color: ${props => props.theme.colors.light};
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto 60px;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

const ServiceCard = styled(motion.div)`
  background: rgba(30, 30, 30, 0.6);
  padding: 40px 30px;
  border-radius: 15px;
  border: 1px solid rgba(255, 77, 0, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: ${props => props.theme.shadows.medium};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.primary};
    transform: translateY(-5px);
  }
`;

const ServiceIcon = styled.div`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 20px;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: ${props => props.theme.colors.light};
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.light};
  opacity: 0.8;
  line-height: 1.6;
`;

const Services = () => {
  const services = [
    {
      title: "Professional Sound Systems",
      description: "State-of-the-art sound equipment delivering crystal clear audio for venues of any size. Experience superior sound quality that brings your event to life.",
      icon: <FaMusic />
    },
    {
      title: "Lighting Solutions",
      description: "Dynamic lighting setups with intelligent fixtures, LED systems, and atmospheric effects to create the perfect ambiance for your event.",
      icon: <FaLightbulb />
    },
    {
      title: "Event Production",
      description: "Comprehensive event production services including technical planning, setup, and live support for seamless execution.",
      icon: <FaTheaterMasks />
    },
    {
      title: "Audio Engineering",
      description: "Expert audio engineering ensuring perfect sound mixing and monitoring for live performances and corporate events.",
      icon: <FaMicrophone />
    },
    {
      title: "Visual Production",
      description: "High-quality video production, LED walls, and projection mapping to create immersive visual experiences.",
      icon: <FaVideo />
    },
    {
      title: "Technical Support",
      description: "24/7 technical support and equipment maintenance to ensure your event runs smoothly from start to finish.",
      icon: <FaCog />
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <ServicesSection id="services">
      <Container>
        <SectionTitle
          as={motion.h2}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Our Services
        </SectionTitle>
        <SectionSubtitle
          as={motion.p}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Delivering exceptional audio-visual experiences through cutting-edge technology and expert service
        </SectionSubtitle>
        <ServicesGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              variants={cardVariants}
              whileHover={{ scale: 1.05 }}
            >
              <ServiceIcon>{service.icon}</ServiceIcon>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Container>
    </ServicesSection>
  );
};

export default Services; 