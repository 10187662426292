import DOMPurify from 'dompurify';

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const sanitizeInput = (input) => {
  if (typeof window === 'undefined') {
    return input;
  }
  return DOMPurify.sanitize(input);
};

export const validatePhone = (phone) => {
  const regex = /^\+?[\d\s-]{10,}$/;
  return regex.test(phone);
}; 