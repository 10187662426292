import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>Phoenix Global Solutions - Professional Sound & Light Solutions</title>
      <meta name="title" content="Phoenix Global Solutions - Professional Sound & Light Solutions" />
      <meta name="description" content="Leading provider of professional sound and lighting solutions across the United States. Expert audio-visual services for events of all sizes. Premium event production nationwide." />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://phoenixssolutions.com/" />
      <meta property="og:title" content="Phoenix Global Solutions - Professional Sound & Light Solutions" />
      <meta property="og:description" content="Leading provider of professional sound and lighting solutions across the United States. Expert audio-visual services for events of all sizes." />
      <meta property="og:image" content="https://phoenixssolutions.com/images/og-image.jpg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://phoenixssolutions.com/" />
      <meta property="twitter:title" content="Phoenix Global Solutions - Professional Sound & Light Solutions" />
      <meta property="twitter:description" content="Leading provider of professional sound and lighting solutions across the United States. Expert audio-visual services for events of all sizes." />
      <meta property="twitter:image" content="https://phoenixssolutions.com/images/og-image.jpg" />

      {/* Keywords */}
      <meta name="keywords" content="sound systems, lighting solutions, event production, audio visual services, concert production, corporate events, music festivals, sound equipment rental, lighting rental, event technology, professional audio, stage lighting, USA events, nationwide event services" />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content="Phoenix Global Solutions" />
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Phoenix Global Solutions",
            "image": "https://phoenixssolutions.com/logo.png",
            "description": "Professional sound and lighting solutions for events across the United States",
            "url": "https://phoenixssolutions.com",
            "telephone": "818-984-7763",
            "email": "info@phoenixssolutions.com",
            "areaServed": "United States",
            "priceRange": "$$",
            "sameAs": [
              "https://facebook.com/phoenixgsolutions",
              "https://instagram.com/phoenixgsolutions"
            ],
            "serviceType": [
              "Sound Systems",
              "Lighting Solutions",
              "Event Production",
              "Audio Engineering",
              "Visual Production",
              "Technical Support"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO; 