import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

const theme = {
  colors: {
    primary: '#FF4D00', // Fire orange
    secondary: '#FF8C00', // Dark orange
    tertiary: '#FFD700', // Golden yellow for accents
    dark: '#121212', // Almost black
    darkGrey: '#1E1E1E',
    light: '#FFFFFF',
    gradients: {
      primary: 'linear-gradient(135deg, #FF4D00 0%, #FF8C00 100%)',
      dark: 'linear-gradient(180deg, #121212 0%, #1E1E1E 100%)',
      overlay: 'linear-gradient(rgba(18, 18, 18, 0.9), rgba(255, 77, 0, 0.8))'
    }
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px'
  },
  shadows: {
    small: '0 2px 8px rgba(255, 77, 0, 0.15)',
    medium: '0 4px 16px rgba(255, 77, 0, 0.2)',
    large: '0 8px 30px rgba(255, 77, 0, 0.25)'
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Phoenix Global Solutions - Professional Sound & Light Solutions</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
