import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import SEO from './components/SEO';
import LoadingScreen from './components/LoadingScreen';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import VideoModal from './components/VideoModal';

const AppContainer = styled.div`
  min-height: 100vh;
  width: 100%;
`;

function App() {
  const [loading, setLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <AppContainer>
      <SEO />
      <GlobalStyles />
      <Navbar />
      <main>
        <Hero setShowVideo={setShowVideo} />
        <Services />
        <About />
        <Portfolio />
        <Contact />
      </main>
      <Footer />
      <VideoModal 
        isOpen={showVideo} 
        onClose={() => setShowVideo(false)}
        videoUrl="https://www.youtube.com/embed/your-video-id"
      />
    </AppContainer>
  );
}

export default App;
