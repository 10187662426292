import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  background: ${({ scrolled }) => scrolled ? 'rgba(18, 18, 18, 0.95)' : 'transparent'};
  backdrop-filter: ${({ scrolled }) => scrolled ? 'blur(10px)' : 'none'};
  transition: all 0.3s ease;
  z-index: 1000;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 20px;
  }
`;

const Logo = styled.div`
  cursor: pointer;
  img {
    height: 60px;
    width: auto;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: ${props => props.theme.colors.light};
  font-size: 1.1rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${props => props.theme.colors.light};
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colors.gradients.dark};
  padding: 100px 20px;
  z-index: 1000;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const MobileLink = styled(motion.a)`
  color: ${props => props.theme.colors.light};
  font-size: 1.5rem;
  text-align: center;
  padding: 15px;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  transition: all 0.3s ease;
  background: rgba(255, 77, 0, 0.1);

  &:hover {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.light};
  }
`;

const DevelopedBy = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${props => props.theme.colors.light};
  opacity: 0.7;
  font-size: 0.9rem;
`;

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuVariants = {
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.3
      }
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  const linkVariants = {
    closed: {
      opacity: 0,
      y: 20
    },
    open: {
      opacity: 1,
      y: 0
    }
  };

  const links = [
    { href: "#home", text: "Home" },
    { href: "#services", text: "Services" },
    { href: "#about", text: "About" },
    { href: "#portfolio", text: "Portfolio" },
    { href: "#contact", text: "Contact" }
  ];

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <Nav scrolled={scrolled}>
      <Logo>
        <a href="#home">
          <img src="/logo.png" alt="Phoenix Global Solutions" />
        </a>
      </Logo>
      <NavLinks>
        {links.map((link, index) => (
          <NavLink key={index} href={link.href}>
            {link.text}
          </NavLink>
        ))}
      </NavLinks>
      <MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </MobileMenuButton>
      <AnimatePresence>
        {isOpen && (
          <MobileMenu
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <Logo style={{ marginBottom: '30px' }}>
              <img src="/logo.png" alt="Phoenix Global Solutions" />
            </Logo>
            {links.map((link, index) => (
              <MobileLink
                key={index}
                href={link.href}
                onClick={handleLinkClick}
                variants={linkVariants}
                initial="closed"
                animate="open"
                transition={{ delay: index * 0.1 }}
              >
                {link.text}
              </MobileLink>
            ))}
            <DevelopedBy>
              Developed by Olexto Digital Solutions
            </DevelopedBy>
          </MobileMenu>
        )}
      </AnimatePresence>
    </Nav>
  );
};

export default Navbar; 