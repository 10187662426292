import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutSection = styled.section`
  padding: 100px 0;
  background-color: ${props => props.theme.colors.dark};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 50px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const ImageContainer = styled(motion.div)`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const Content = styled(motion.div)`
  flex: 1;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 30px;
`;

const Description = styled.p`
  color: ${props => props.theme.colors.light};
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 1.1rem;
  opacity: 0.9;
`;

const HighlightBox = styled(motion.div)`
  background: rgba(255, 77, 0, 0.1);
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
`;

const HighlightText = styled.p`
  color: ${props => props.theme.colors.light};
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
`;

const About = () => {
  return (
    <AboutSection id="about">
      <Container>
        <ImageContainer
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <img 
            src="https://images.unsplash.com/photo-1492684223066-81342ee5ff30?q=80&w=2070" 
            alt="About Phoenix Global Solutions" 
          />
        </ImageContainer>
        <Content
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Title
            as={motion.h2}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            About Us
          </Title>
          <Description
            as={motion.p}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            Phoenix Global Solutions delivers premium sound and lighting solutions across all 50 states. 
            From intimate gatherings to large-scale festivals, our nationwide presence ensures that no 
            matter where your event is located in the United States, we're ready to deliver exceptional 
            audio-visual experiences.
          </Description>
          <Description
            as={motion.p}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            With strategic locations across the country and a mobile fleet of state-of-the-art equipment, 
            we bring world-class production capabilities to your doorstep. Our nationwide network of 
            technicians and engineers ensures consistent, high-quality service whether you're planning an 
            event in New York, Miami, Los Angeles, or anywhere in between.
          </Description>
          <HighlightBox
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <HighlightText>
              From coast to coast, we're your trusted partner for event production excellence. 
              Whether it's a corporate conference in Chicago, a music festival in Austin, or a 
              special event in Seattle - we've got you covered nationwide with our premium sound 
              and lighting solutions.
            </HighlightText>
          </HighlightBox>
        </Content>
      </Container>
    </AboutSection>
  );
};

export default About; 